.ant-input, .ant-input-number {
  border-radius: 4px;
  &:focus {
    box-shadow: none;
  }
  &:focus, &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  box-shadow: none
}

.ant-input {
  padding: 8px 11px;
}

.ant-input-number {
  padding: 4px 11px;
}

.ant-input-number-input {
  font-family: 'Open Sans';
  font-size: 16px;
}

.ant-input-number-handler-down {
  border-bottom-right-radius: 4px
}

.ant-input-number-handler-up {
  border-top-right-radius: 4px
}

.ant-input-number-handler-wrap {
  border-radius: 0 4px 4px 0
}

.ant-input-number-focused {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.87)
}




