.scroll-select-container {
  position: relative;
  height: 100%;
}

.scroll-selector-area {
  position: absolute;
  border-top: 1px solid rgb(197, 197, 197);
  border-bottom: 1px solid rgb(197, 197, 197);
  width: 99%;
  z-index: -1;
}

.scroll-select-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  color: rgb(187, 187, 187);
  position: relative;
  perspective: 1000px;
  background: #f0f1f2;
}

.scroll-select-list::-webkit-scrollbar {
  width: 0 !important;
}

.scroll-item {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* text-align: right; */
  padding-right: 10px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  color: rgb(50, 50, 50);
  font-weight: bold;
}

.wheel-item {
  background: rgb(249, 249, 249);
  border: 1px solid rgb(177, 177, 177);
}

.selected-time {
  font-weight: bold;
  transition: all 0.2s;
  color: rgb(50, 50, 50);
  background: #f0f1f2;
}
