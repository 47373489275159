// Import style for Ant Design
@import '~antd/dist/antd.css';
@import './antd/index.scss';

:root {
  --bodyColor: #f4f7fe;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bodyColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* icon */
i.material-icons {
  font-size: 18px;

  @media (min-width: 1920px) {
    font-size: 20px;
  }
}

.textInput4 {
  width: 32%;
  margin: 0.6% !important;
  float: left;
}

.clearfix {
  clear: both;
}

// always show scroll bar
.datagrid-wrapper {
  overflow-y: auto;
}

.datagrid-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.datagrid-wrapper::-webkit-scrollbar:vertical {
  width: 11px;
}

.datagrid-wrapper::-webkit-scrollbar:horizontal {
  height: 11px;
}

.datagrid-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.list-page > div:nth-child(2) {
  overflow: hidden;
  padding: 2px;
}

.datagrid-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 16px 16px 8px;
}

.date-range-input {
  input {
    width: 200px;
  }
}

.tui-editor-contents {
  p:first-child {
    margin-top: 0;
  }
}

.tui-image {
  // don't use image markdown
  display: none;
}

.custom-image-field img {
  width: 70%;
  max-height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.hidden-label-filter button.add-filter span:first-child > span {
  display: none;
}

.button-add-column {
  background-color: #0f417b !important;
  color: white !important;
  position: fixed !important;
  top: auto;
  left: auto;
  color: #fff;
  right: 20px;
  bottom: 60px;
  margin: 0;
  z-index: 1000;

  @media (max-width: 960px) {
    bottom: 130px;
  }
}

.datagrid-wrapper {
  .column-ownerId {
    label {
      display: none !important;
    }
  }
}

.button-add-filter {
  background-color: #0f417b !important;
  color: white !important;
  position: fixed !important;
  top: auto;
  left: auto;
  color: #fff;
  right: 20px;
  bottom: 130px;
  margin: 0;
  z-index: 1000;

  @media (max-width: 960px) {
    bottom: 200px;
  }
}

.custom-image-field > img {
  height: 50px;
  width: 50px;
}

.date-input {
  input {
    min-width: 0;
    width: 135px;
  }
}

.pointer-event-none {
  pointer-events: none;
}

.multiline-able-copy-wrapper {
  width: 95% !important;
  word-break: break-all;
}

.ra-field > div {
  & > label {
    color: #a5a5a5;
    font-weight: 700;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.arr-templates ul {
  padding-top: 20px;
}

.CustomRaInput_none-label {
  label {
    display: none;
  }

  fieldset > legend span {
    display: none !important;
  }
}

.CustomRaInput_none-label-focused {
  label.Mui-focused {
    display: none;
  }

  fieldset > legend span {
    display: none !important;
  }
}

.CustomRaInput_none-loading-icon {
  [class^='MuiCircularProgress'] {
    display: none;
  }
}

.date-picker-input {
  .ant-picker-input > input {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    line-height: 30px;
  }
}

.column-lockSpinning {
  max-width: 150px !important;
  width: 150px !important;
}

.hidden {
  visibility: hidden;
}
